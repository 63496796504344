import React, { useEffect } from 'react'
import IframeComponent from '../components/Iframe/Iframe'
import { useLocation } from 'react-router-dom';

function Home() {
  const { state, page } = useLocation()

  
  useEffect(() => {
    // console.log(state, "hellll")
    generateAuthToken()
  }, []);

  const generateAuthToken = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch("https://mahindranode.azurewebsites.net/getEmbedToken", requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(JSON.parse(result))
        localStorage.setItem("embedToken", JSON.parse(result).accessToken)
      })
      .catch(error => console.log('error', error));
      
  }

  return (
    <>
      <IframeComponent key={state?.state == new Date() ? '986f4c09-1d57-4d08-9351-5ca417136fe6' : state?.state} state={state?.state == null || state?.state == '' ? "986f4c09-1d57-4d08-9351-5ca417136fe6" : state?.state} page={state?.page == null || state?.page == '' ? '' : state?.page} />
    </>
  )
}

export default Home