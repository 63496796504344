import "./App.css";
import { BrowserRouter, Route, Routes, useNavigate, useNavigation } from "react-router-dom";
import React, { useEffect } from 'react';
import { ROUTES } from "./constant";
import Home from "./pages/home";
// import About from "./pages/about";
// import Contact from "./pages/contact";
// import Profile from "./pages/profile";
import HeaderBar from "./components/Header/Header";
import Navbar from "./components/Sidebar/Navbar";
import { HashRouter } from 'react-router-dom'
import Login from "./pages/login";
import { PageLayout } from "./components/PageLayout"
import { useIsAuthenticated } from '@azure/msal-react';
// import { loginRequest } from './authConfig';
// import { callMsGraph } from './graph';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

function App() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated || isAuthenticated == undefined || isAuthenticated == null || isAuthenticated == '') {
      navigate('/login')
    } else {
      navigate(ROUTES.PVCUSTOMSALES)
    }
  }, [isAuthenticated])
  return (
    <div className="App" key={isAuthenticated}>
      {/* <HashRouter> */}
      <UnauthenticatedTemplate>
        <Routes>
          <Route path={"/login"} element={<Login />} />
          {/* <Route path={"/"} element={<Login />} /> */}
        </Routes>

        {/* <Route path={"/login"} element={
              <PageLayout>
            <Login />
              </PageLayout>
            } />
          </Routes> */}
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <div className="wrapper d-flex align-items-stretch">
          <Navbar />
          <div className="content p-2 p-md-2 pt-3" key={isAuthenticated}>
            <HeaderBar />
            <Routes>
              {/* <Route path={ROUTES.HOME} element={<Home />} /> */}
              {/* <Route path={ROUTES.PU} element={<Home />} />
              <Route path={ROUTES.SCV} element={<Home />} />
              <Route path={ROUTES.IO} element={<Home />} />
              <Route path={ROUTES.CE} element={<Home />} /> */}
              <Route path={ROUTES.PVCUSTOMSALES} element={<Home />} />
              <Route path={ROUTES.PVDASHBOARD} element={<Home />} />
              <Route path={ROUTES.PVDELIGHTTOWIN} element={<Home />} />
              <Route path={ROUTES.PVCUSTOMDELIGHTTOWIN} element={<Home />} />
              <Route path={ROUTES.PVSALES} element={<Home />} />
              <Route path={ROUTES.PVCUSTOMMANUFACTURE} element={<Home />} />
              <Route path={ROUTES.PVSERVICE} element={<Home />} />
              <Route path={ROUTES.PVMARKETING} element={<Home />} />
              <Route path={ROUTES.PVMANUFACTURING} element={<Home />} />
              <Route path={ROUTES.PVADC} element={<Home />} />
              <Route path={ROUTES.PVHR} element={<Home />} />
              {/* <Route path={ROUTES.ABOUT} element={<About />} />
              <Route path={ROUTES.CONTACT} element={<Contact />} />
              <Route path={ROUTES.PROFILE} element={<Profile />} /> */}
            </Routes>
          </div>
        </div>
      </AuthenticatedTemplate>

    </div>
  );
}

export default App;
