export const ROUTES = {
  HOME: "/pv-custom-sales",
  PU: "/pu",
  SCV: "/scv",
  IO: "/io",
  CE: "/ce",
  PVDASHBOARD: "/pv-dashboard",
  PVDELIGHTTOWIN: "/pv-delight-to-win",
  PVSALES: "/pv-sales",
  PVCUSTOMSALES: "/pv-custom-sales",
  PVCUSTOMMANUFACTURE: "/pv-custom-manufacturing",
  PVCUSTOMDELIGHTTOWIN: "/pv-custom-delight-to-win",
  PVSERVICE: "/pv-service",
  PVMARKETING: "/pv-marketing",
  PVMANUFACTURING: "/pv-manufacturing",
  PVADC: "/pv-adc",
  PVHR: "/pv-hr"
};