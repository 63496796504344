import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { SidebarData } from "./SliderbarData";
import "./Navbar.css";
import logo from "../../47db82d107e80ff57af118737ff13216.png";
import * as IoIcons from "react-icons/io";
export default function Navbar() {
  const [activeClass, setActiveClass] = useState({ 'PVClass': '' });

  useEffect(() => {
    var route = { "PV": ['/pv', '/pv-delight-to-win', '/pv-sales', '/pv-service', '/pv-marketing', '/pv-adc', '/pv-hr'] }
    var currentPath = window.location.pathname
    if (route.PV.includes(currentPath)) {
      setActiveClass({ 'PVClass': 'active' });
    } else {
      setActiveClass({ 'PVClass': '' });
    }
  }, [])

  const handelClick = (data) => {

  }

  return (
    <>
      <nav className="sidebar">
        <div className="components">

          <div className="logoSidebar">
            <img style={{ width: "125px" }} src={logo} alt="Mahindra & Mahindra" />
          </div>


          <ul className="list-unstyled mb-2">

            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName} style={{background:"#F2F2F2", boxShadow:"inset 4px 0px 0px #FF3E5B"}}>
                  {item.SubMenu.length > 0 ?
                    <a className={activeClass?.PVClass + " dropdown-toggle"} data-toggle="dropdown">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M18.9743 7.67366L12.7383 2.59271C11.7754 1.80397 10.2186 1.80397 9.26478 2.58353L3.02872 7.67366C2.32683 8.24228 1.87689 9.44373 2.02987 10.3425L3.22669 17.643C3.44266 18.9453 4.66647 20 5.96228 20H16.0408C17.3276 20 18.5604 18.9361 18.7763 17.643L19.9732 10.3425C20.1171 9.44373 19.6672 8.24228 18.9743 7.67366ZM11.0015 14.2129C9.7597 14.2129 8.75186 13.1857 8.75186 11.92C8.75186 10.6544 9.7597 9.62716 11.0015 9.62716C12.2433 9.62716 13.2512 10.6544 13.2512 11.92C13.2512 13.1857 12.2433 14.2129 11.0015 14.2129Z" fill="#FF3E5B" />
                      </svg>
                      <span style={{padding:"10px", color:"rgb(255 62 91)"}}>Home</span>
                    </a>
                    :
                    <NavLink to={item.path} state={{ state: item.iFrame, page: item.pageName }} className={item.SubMenu.length > 0 ? 'dropdown-toggle' : ''}>
                      <span>{item.title}</span>
                    </NavLink>
                  }

                  {item.SubMenu.length > 0 ?
                    <ul className="dropdown-menu show">
                      {item?.SubMenu.length > 0 && item.SubMenu.map((paths, i) => {
                        return (<li key={i}>
                          <NavLink to={paths.path} state={{ state: paths.iFrame, page: paths.pageName }}>
                            <span>{paths.title}</span>
                          </NavLink>
                        </li>)
                      })}
                    </ul> : ''}
                </li>
              );
            })}

          </ul>
        </div>
      </nav>
    </>
  );
}
