import React, { useState, useEffect } from "react";
import logo from "../../47db82d107e80ff57af118737ff13216.png";
import logoOut from "../../logout.png";
import userProfile from "../../userProfile.png";
import "./Header.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';
import { callMsGraph } from '../../graph';

export default function HeaderBar() {

  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [userImage, setUserImage] = useState(null);

  useEffect(() => {
    RequestProfileData()
    getPhoto()
  }, []);


  async function getPhoto() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://graph.microsoft.com/v1.0/users/50005281@mahindra.com/photo/$value", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (JSON.parse(result).error?.code == 'ImageNotFound') {

        } else {
          localStorage.setItem("userImage", JSON.parse(result))
          setUserImage(JSON.parse(result))
        }
      })
      .catch(error => console.log('error', error));
  }



  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        localStorage.setItem("accessToken", response.accessToken)
        callMsGraph(response.accessToken).then((response) => setGraphData(response));

      });
  }


  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }

  return (
    <>
      <div className="topbar d-flex align-items-center">
        <div className="user">
          <h2>DHRUVA</h2>
        </div>
        <div className="userdetail pl-4">
          <div className="members-info-div d-flex">
            <div className="image-div">
            </div>
            <div className="name-div" style={{ paddingTop: "0.3rem" }}>
              <span className="ms-2 welcome-text" style={{ color: "#2d2c2c", paddingRight:"9px" }}>Welcome, {graphData?.displayName}</span>
              {/* <br></br>
              <span className="ms-2 info-text" style={{ color: "#797877" }}>{graphData?.userPrincipalName}</span> */}
            </div>
            <div onClick={() => handleLogout("popup")} style={{ display: "flex", alignItems: 'center' }}>
              {/* <img className="logoutImage" src={logoOut} /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.6001 2.59998C1.6001 2.04769 2.04781 1.59998 2.6001 1.59998H10.4001C10.9524 1.59998 11.4001 2.04769 11.4001 2.59998V4.08693C11.4001 4.30785 11.221 4.48693 11.0001 4.48693C10.7792 4.48693 10.6001 4.30785 10.6001 4.08693V2.59998C10.6001 2.48952 10.5106 2.39998 10.4001 2.39998H2.6001C2.48964 2.39998 2.4001 2.48952 2.4001 2.59998V13.4C2.4001 13.5104 2.48964 13.6 2.6001 13.6H10.4001C10.5106 13.6 10.6001 13.5104 10.6001 13.4V11.913C10.6001 11.6921 10.7792 11.513 11.0001 11.513C11.221 11.513 11.4001 11.6921 11.4001 11.913V13.4C11.4001 13.9523 10.9524 14.4 10.4001 14.4H2.6001C2.04781 14.4 1.6001 13.9523 1.6001 13.4V2.59998ZM11.5037 5.94582C11.6441 5.77523 11.8962 5.75073 12.0668 5.8911L14.2543 7.6911C14.3466 7.76708 14.4001 7.88039 14.4001 7.99998C14.4001 8.11956 14.3466 8.23287 14.2543 8.30885L12.0668 10.1088C11.8962 10.2492 11.6441 10.2247 11.5037 10.0541C11.3634 9.88355 11.3879 9.63147 11.5584 9.4911L12.8845 8.39998H8.0001C7.77918 8.39998 7.6001 8.22089 7.6001 7.99998C7.6001 7.77906 7.77918 7.59998 8.0001 7.59998H12.8845L11.5584 6.50885C11.3879 6.36848 11.3634 6.1164 11.5037 5.94582Z" fill="#FF3E5B" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
