import React from "react";


import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import { ROUTES } from "../../constant";


export const SidebarData = [
  // {
  //   title: "PV Dashboard",
  //   path: ROUTES.HOME,
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: "nav-text",
  //   iFrame: "090eccb6-f24f-44ac-9843-8dce986150f8",
  //   pageName: "ReportSectiona20b6dfd08cea73438a7",
  //   SubMenu: []
  // },
  // {
  //   title: "PV Dashboard",
  //   path: ROUTES.PVDASHBOARD,
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: "nav-text",
  //   iFrame: "",
  //   pageName: "",
  //   SubMenu: []
  // },
  // {
  //   title: "Delight to win",
  //   path: ROUTES.PVDELIGHTTOWIN,
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: "nav-text",
  //   iFrame: "5603d789-eb16-4eb8-ac05-5bd769e8ebd7",
  //   pageName: "ReportSectionb477cec201cd71ea4cee",
  //   SubMenu: []
  // },
  // {
  //   title: "Sales",
  //   path: ROUTES.PVSALES,
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: "nav-text",
  //   iFrame: "986f4c09-1d57-4d08-9351-5ca417136fe6",
  //   pageName: "ReportSectionb477cec201cd71ea4cee",
  //   SubMenu: []
  // },
  // {
  //   title: "Manufacturing",
  //   path: ROUTES.PVMANUFACTURING,
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: "nav-text",
  //   iFrame: "23ec1401-f148-4ad9-be05-0eabbf6087f7",
  //   pageName: "ReportSectionb477cec201cd71ea4cee",
  //   SubMenu: []
  // },
  // {
  //   title: "Sales",
  //   path: ROUTES.PVCUSTOMSALES,
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: "nav-text",
  //   iFrame: "986f4c09-1d57-4d08-9351-5ca417136fe6",
  //   pageName: "ReportSection805e31a31ddbde516e7e",
  //   SubMenu: []
  // },
  // {
  //   title: "Manufacturing",
  //   path: ROUTES.PVCUSTOMMANUFACTURE,
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: "nav-text",
  //   iFrame: "23ec1401-f148-4ad9-be05-0eabbf6087f7",
  //   pageName: "ReportSection9e294395c2a61392590c",
  //   SubMenu: []
  // },
  // {
  //   title: "Delight to win ",
  //   path: ROUTES.PVCUSTOMDELIGHTTOWIN,
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: "nav-text",
  //   iFrame: "5603d789-eb16-4eb8-ac05-5bd769e8ebd7",
  //   pageName: "ReportSection1210ad00d70646a07204",
  //   SubMenu: []
  // },
  {
    title: "Home",
    path: ROUTES.PVDASHBOARD,
    icon: <IoIcons.IoMdPeople />,
    cName: "dropdown show",
    iFrame: "",
    SubMenu: [
      {
        title: "Sales",
        path: ROUTES.PVCUSTOMSALES,
        icon: <IoIcons.IoMdPeople />,
        cName: "nav-text",
        iFrame: "986f4c09-1d57-4d08-9351-5ca417136fe6",
        pageName: "ReportSection805e31a31ddbde516e7e",
        SubMenu: []
      },
      {
        title: "Manufacturing",
        path: ROUTES.PVCUSTOMMANUFACTURE,
        icon: <IoIcons.IoMdPeople />,
        cName: "nav-text",
        iFrame: "23ec1401-f148-4ad9-be05-0eabbf6087f7",
        pageName: "ReportSection9e294395c2a61392590c",
        SubMenu: []
      },
      {
        title: "Delight to win ",
        path: ROUTES.PVCUSTOMDELIGHTTOWIN,
        icon: <IoIcons.IoMdPeople />,
        cName: "nav-text",
        iFrame: "5603d789-eb16-4eb8-ac05-5bd769e8ebd7",
        pageName: "ReportSection1210ad00d70646a07204",
        SubMenu: []
      },
    ]
  },
];

// export const SidebarData = [
//   {
//     title: "Overview",
//     path: ROUTES.HOME,
//     icon: <AiIcons.AiFillHome />,
//     cName: "nav-text",
//     iFrame: "",
//     SubMenu: []
//   },
//   {
//     title: "PU",
//     path: ROUTES.PU,
//     icon: <IoIcons.IoIosPerson />,
//     cName: "nav-text",
//     iFrame: "",
//     SubMenu: []
//   },
//   {
//     title: "SCV",
//     path: ROUTES.SCV,
//     icon: <FaIcons.FaCartPlus />,
//     cName: "nav-text",
//     iFrame: "",
//     SubMenu: []
//   },
//   {
//     title: "IO",
//     path: ROUTES.IO,
//     icon: <IoIcons.IoMdPeople />,
//     cName: "nav-text",
//     iFrame: "",
//     SubMenu: []
//   },
//   {
//     title: "CE",
//     path: ROUTES.CE,
//     icon: <IoIcons.IoMdPeople />,
//     cName: "nav-text",
//     iFrame: "",
//     SubMenu: []
//   },
//   {
//     title: "PV",
//     path: ROUTES.PVDASHBOARD,
//     icon: <IoIcons.IoMdPeople />,
//     cName: "dropdown",
//     iFrame: "",
//     SubMenu: [
//       {
//         title: "PV Dashboard",
//         path: ROUTES.PVDASHBOARD,
//         icon: <IoIcons.IoMdPeople />,
//         cName: "nav-text",
//         iFrame: "https://app.powerbi.com/reportEmbed?reportId=5bc35b11-95c1-42da-a39c-fb06b029a8a0&autoAuth=true&ctid=8c4858b5-f020-483a-b7ef-71ded6e81767",
//       },
//       {
//         title: "Delight to win",
//         path: ROUTES.PVDELIGHTTOWIN,
//         icon: <IoIcons.IoMdPeople />,
//         cName: "nav-text",
//         iFrame: "https://app.powerbi.com/reportEmbed?reportId=4e71aab2-aac7-44ed-94f2-e2cf0b58d2b6&autoAuth=true&ctid=8c4858b5-f020-483a-b7ef-71ded6e81767",
//       },
//       {
//         title: "Sales",
//         path: ROUTES.PVSALES,
//         icon: <IoIcons.IoMdPeople />,
//         cName: "nav-text",
//         iFrame: "https://app.powerbi.com/reportEmbed?reportId=986f4c09-1d57-4d08-9351-5ca417136fe6&autoAuth=true&ctid=8c4858b5-f020-483a-b7ef-71ded6e81767",
//       },
//       {
//         title: "Services",
//         path: ROUTES.PVSERVICE,
//         icon: <IoIcons.IoMdPeople />,
//         cName: "nav-text",
//         iFrame: "",
//       },
//       {
//         title: "Marketing",
//         path: ROUTES.PVMARKETING,
//         icon: <IoIcons.IoMdPeople />,
//         cName: "nav-text",
//         iFrame: "",
//       },
//       {
//         title: "ADC",
//         path: ROUTES.PVADC,
//         icon: <IoIcons.IoMdPeople />,
//         cName: "nav-text",
//         iFrame: "",
//       },
//       {
//         title: "HR",
//         path: ROUTES.PVHR,
//         icon: <IoIcons.IoMdPeople />,
//         cName: "nav-text",
//         iFrame: "",
//       }
//     ]
//   },
// ];
