import React, { useState, useEffect } from "react";
import Iframe from 'react-iframe'
import "./Iframe.css";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from "powerbi-client";

export default function IframeComponent(props) {
  const [accessToken, setAccessToken] = useState(localStorage.getItem("`accessToken`"));
  const [embedToken, setEmbedToken] = useState(localStorage.getItem("embedToken"));
  const [accessTokenPowerBi, setAccessTokenPowerBi] = useState(null);

  useEffect(() => {
    console.log(props?.state, "hello i am here")
    getEmbadeToken()
  }, [props?.state]);

  const getEmbadeToken = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + embedToken);
    var raw = JSON.stringify({
      "accessLevel": "View",
      "identities": []
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`https://api.powerbi.com/v1.0/myorg/groups/5c2a4eaa-04a8-4aaf-8bab-9bd6b9451140/reports/${props?.state}/GenerateToken`, requestOptions)
      .then(response => response.text())
      .then(result => {
        // console.log(JSON.parse(result))
        setAccessTokenPowerBi(JSON.parse(result).token)
      })
    //   .catch(error => console.log('error', error));
  }

  return (
    <>
      {props?.state != '' && accessTokenPowerBi != null ?
        // <Iframe url={props.state}
        //   width="100%"
        //   height="110%"
        //   id=""
        //   className=""
        //   display="block"
        //   position="relative" />
        // : <p className="noReport">No Report Found</p>

        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: props?.state,
            embedUrl: 'https://app.powerbi.com/reportEmbed',
            accessToken: accessTokenPowerBi,
            tokenType: models.TokenType.Embed,
            pageName: props?.page,
            settings: {
              filterPaneEnabled: true,
              navContentPaneEnabled: false,           
            }
          }}
          cssClassName={"reportClass"}
        /> : <p className="noReport">Loading...</p>
      }
    </>
  );
}
